import { Link } from 'react-router-dom';
import { BiLogo, BiBidease } from '@bidease/ui-kit';

import routes from 'constants/routes';

export const LogoLink = () => (
  <Link to={routes.default} aria-label='bidease'>
    <BiLogo size={18} />
  </Link>
);

export const LogoBox = () => (
  <span className='inline-block rounded bg-white dark:bg-twilight-active dark:text-smoke md:w-[13rem]'>
    <span className='inline-flex h-16 w-16 items-center justify-center border-r-border dark:border-r-twilight md:border-r'><BiLogo size={18} /></span>
    <span className='hidden h-16 items-center px-4 md:inline-flex'><BiBidease /></span>
  </span>
);
