import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';

import ClientError from 'components/BaseLayout/ClientError';

import { loggerCaptureException } from 'helpers/logger';
import { generateHash } from 'helpers/utils';

export default function RouterErrorBoundry() {
  const error = useRouteError();
  const uid = generateHash();

  useEffect(() => {
    if (error) {
      loggerCaptureException(error as Error, { info: error, uid: uid }, 'router_error_boundary');
    }
  }, [error, uid]);

  if (isRouteErrorResponse(error)) {
    return <ClientError uid={uid} />;
  } else if (error instanceof Error) {
    const message = error.message.includes('Failed to fetch dynamically') ? `[${error.name}] The new version has been released` : undefined;
    return <ClientError uid={uid} text={message} />;
  } else {
    return <ClientError uid={uid} />;
  }
}
