import { Footer } from '@bidease/ui-kit';

import Helmet from 'components/Helmet';
import { LogoBox } from 'components/Logo';

interface IProps {
  title?: string;
  color?: string;
  className?: string;
  children: React.ReactNode;
}

const ServiceLayout: React.FC<IProps> = ({ children, title }) => (
  <section className='flex min-h-svh flex-col justify-between p-12 dark:bg-black'>
    <Helmet title={title} />

    <LogoBox />

    <main>{children}</main>

    <Footer version={import.meta.env.REACT_APP_BUILD_VERSION} />
  </section>
);

export default ServiceLayout;
