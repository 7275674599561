import {
  EnumBidCPMType,
  EnumBundleType,
  EnumCampaignState,
  EnumColdstartStatus,
  EnumCreationType,
  EnumCreativeOrientation,
  EnumCreativeType,
  EnumCreativeViewabilityProvider,
  EnumFilterEntityType,
  EnumMarkupState,
  EnumNullableBoolean,
  EnumOpenRTBApiFramework,
  EnumOpenRTBVastProtocol,
  EnumOperationSystem,
  EnumOrganizationStatus,
  EnumPaceKeeperStatus,
  EnumProductInitialAttributionType,
  EnumProductType,
  EnumReportTaskLevel,
  EnumResourceType,
  EnumTargetingContainerState,
  GoalDto,
} from 'models/objects';

import { EnumCountServerResponseDefault } from './enum';
import { IBasicProductValidation } from 'validation/product';
import { ICreateSegmentForRekl } from 'validation/segments';
import { IPasswordChange, IUser } from 'validation/user';
import { IMarkupContentResourceListItem, IMarkupValidation, IMarkupVariableListItem } from 'validation/markups';
import { IBundleCreate } from 'validation/bundle';
import { IReportCreator } from 'validation/reports';
import { IBidCPMValidation, ICampaignValidation } from 'validation/campaigns';
import { IOrganizationCRUDValidation } from 'validation/organization';
import { IMLearnTaskCreateValidation } from 'validation/models';
import { IKeyValuePairs } from 'validation/helpers';
import { ICreativeValidation } from 'validation/creatives';
import { ISharingUserAdvertiser } from 'validation/advertiser';
import { IFilterAndElement } from 'validation/globalStatsFilter';
import { IFindResource, IUploadResource } from 'validation/resource';

export const DefaultSelectOptions = {
  content: [],
  targetingContainerState: EnumTargetingContainerState.notSpecified,
};

export const newGoal: GoalDto = {
  goalID: 0,
  position: 0,

  isImportant: false,
  code: '',
  payout: 0,
  target: 0,
  revenueShare: 0,
  payableUnique: false,
  payableDuration: 0,
};

export const newResourceList: IMarkupContentResourceListItem = {
  name: '', // Resource name
  description: '', // Description of resource
  type: '' as any,
  isRequired: false, // Is Resource required
  testValue: '' as any, // Test resource ID
  order: 0, // Resource order
};

export const newVariableList: IMarkupVariableListItem = {
  key: '', // Variable name
  description: '', // Description of variable
  // @ts-expect-error we wont force user to select type
  type: null, // Editor variable validator (eg. Link, integer, etc.)
  meta: '', // meta information for selector type
  isRequired: false, // Is value required
  defaultValue: '', // Default value
  testValue: '', // Test value
  order: 0,
};

export const markupListRequestData = {
  count: EnumCountServerResponseDefault,
  markupIDList: [],
  search: '',
  typeList: [],
  archived: true,
};

export const bidCPMUpdater = {
  bidCpm: 0,
  maxBidCpm: 0,
  bidFloorCap: 0,
};

const DefaultTracker = {
  queryParams: [],
  disabled: false,
  baseUrlParams: [],
  baseUrl: '',
  preview: '',
};
export const newProduct: IBasicProductValidation = {
  bidAfterORD: false,
  isAutopilot: false,
  isSKAdNetwork: false,
  isStrictGeoCheck: false,
  customTagList: [],
  name: '',
  customUrl: '',
  reklBundleID: 0,
  domain: '',
  dailyRevenueLimit: 0,
  totalRevenueLimit: 0,
  totalRevenue: 0,
  autopilotIDList: [],
  bindingList: [],
  settings: {
    minimumProductMargin: 0,
    isDynamicProductMargin: false,
    productMargin: { presented: false, value: 0 },
  },
  tracking: {
    useDeepLink: false,
    trackerID: 1,
    skadTrackerID: 1,
    targetUrlHttp: '',
    targetUrlMarket: '',
    impressionRedirect: { ...DefaultTracker },
    clickRedirect: { ...DefaultTracker },
    impressionS2S: { ...DefaultTracker },
    clickS2S: { ...DefaultTracker },
    payoutCPM: 0,
    payoutCPC: 0,
    goalList: [],
  },

  targeting: {
    useOsAdFeatures: false,
    frequencySecondsInterstitial: 0,
    frequencySecondsNonInterstitial: 0,
    frequencyClickSeconds: 0,
    cityList: {
      content: [],
      targetingContainerState: EnumTargetingContainerState.notSpecified,
    },
    applicationList: {
      content: [],
      targetingContainerState: EnumTargetingContainerState.notSpecified,
    },
    zipList: {
      content: [],
      targetingContainerState: EnumTargetingContainerState.notSpecified,
    },
    ipList: {
      content: [],
      targetingContainerState: EnumTargetingContainerState.notSpecified,
    },
    osList: {
      content: [],
      targetingContainerState: EnumTargetingContainerState.notSpecified,
    },
  },
  iabCategoryList: [],
  attributionType: EnumProductInitialAttributionType.UserAcquisition,
  type: EnumProductType.PerformanceCpa,
};

export const newOrganization: IOrganizationCRUDValidation = {
  name: '',
  postbackUrl: '',
  isApproveCreatives: true,
  isSetupVideoSizeAllowed: false,
  status: EnumOrganizationStatus.active,
};

export const newSegment: ICreateSegmentForRekl = {
  reklID: 0,
  comment: '',
  url: '',
  file: undefined,
  ifaList: '',
  isSegment: false,
  isSuppression: false,
  isMLSet: false,
  isTestSet: false,
  reklBundleIDList: [],
  creationType: EnumCreationType.url,
};

export const newUser: IUser = {
  isActive: true,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  timeZone: 'UTC',
  permissionList: [],
  organizationIDList: [],
};

export const newPasswordChange: IPasswordChange = {
  password: '',
  newPassword: '',
  repeatNewPassword: ''
};

export const newMarkup: IMarkupValidation = {
  /* @OnlyForFrontend */
  __checkVariables: false,
  name: '',
  description: '',
  templateOriginal: '',
  markupContent: {
    resourceList: [],
    variableList: [],
    variableTestList: [],
  },
  state: EnumMarkupState.Testing,
  type: EnumCreativeType.banner,
  vastVersionList: [EnumOpenRTBVastProtocol.vast1],
  apiList: [EnumOpenRTBApiFramework.Mraid1],
  isPlayable: false,
  isInBannerVideo: false,
  interstitial: EnumNullableBoolean.nulled,

  exchangeIDList: [],
  organizationIDList: [],

  width: null,
  height: null,
  duration: null,
};

export const newBundle: IBundleCreate = {
  reklID: 0,
  bundleType: EnumBundleType.application,
  bundle: '',
  operationSystem: EnumOperationSystem.Ios,
};

export const newLearnTask: IMLearnTaskCreateValidation = {
  name: '',
  goalIDList: [],
  hasIfa: EnumNullableBoolean.disabled,
  retargeting: EnumNullableBoolean.disabled,
  conditionImpression: '',
  conditionGoal: '',
  startDate: '',
  endDate: '',
};

export const newReport: IReportCreator = {
  description: '',
  extraEmailList: [],
  isOneTimeExport: true,
  frequencyDays: 1,
  daysDepth: 10,
  level: EnumReportTaskLevel.product,
};

export const newCustomBidCpm: IBidCPMValidation = {
  application: '',
  placementID: '',
  bid: { type: EnumBidCPMType.bidCpm, bidCpm: 0, maxBidCpm: 0, bidFloorCap: 0.01 },
};

export const newCampaign: ICampaignValidation = {
  basicInfo: {
    campaignState: EnumCampaignState.active,
    name: '',
    description: '',
    clientTitle: '',
    managerID: 0,
    budget: 0,
    markupVariableList: [],
    bindingList: [],
    isValidForML: false,
  },
  productID: 0,
  campaignCPM: {
    bid: {
      type: EnumBidCPMType.bidCpm,
      bidCpm: 0,
      maxBidCpm: 0,
      bidFloorCap: 0.01,
    },
    customBidCPMList: [],
  },
  optimization: {
    useBalancer: false,
    useAutoBL: false,
    autoBLCPIMulti: 1.8,

    dailyRevenueLimit: 0,

    paceKeeperStatus: { value: EnumPaceKeeperStatus.None, presented: false },
    coldstartStatus: { value: EnumColdstartStatus.None, presented: false },

    labelSettings: null,
  },
  targeting: {
    gender: undefined,
    minAge: 0,
    maxAge: 0,
    daysAgoMin: 0,
    daysAgoMax: 0,
    deviceType: undefined,

    hourlyPaceLimit: 300,

    installedByList: [],

    exchangeList: { ...DefaultSelectOptions },
    sspList: { ...DefaultSelectOptions },
    countryList: { ...DefaultSelectOptions },
    iabCategoryList: { ...DefaultSelectOptions },
    operationSystemVersionList: { ...DefaultSelectOptions },
    osList: { ...DefaultSelectOptions },
    connectionTypeList: { ...DefaultSelectOptions },
    makeList: { ...DefaultSelectOptions },
    modelList: { ...DefaultSelectOptions },
    regionList: { ...DefaultSelectOptions },
    cityList: { ...DefaultSelectOptions },
    carrierList: { ...DefaultSelectOptions },
    languageList: { ...DefaultSelectOptions },
    userAgentList: { ...DefaultSelectOptions },
    appNameKeywords: { ...DefaultSelectOptions },
    bundleGenreList: { ...DefaultSelectOptions },
    genreList: { ...DefaultSelectOptions },
    applicationList: { ...DefaultSelectOptions },
    installedApplicationBlackList: { ...DefaultSelectOptions },
    installedApplicationGroupWhiteList: { ...DefaultSelectOptions },

    segmentList: [],

    hasIfa: EnumNullableBoolean.nulled,
    rewarded: EnumNullableBoolean.nulled,
    coppaType: EnumNullableBoolean.nulled,
    allowInstalled: false,
    skadNetwork: EnumNullableBoolean.nulled,
    osVersionMax: '',
    osVersionMin: '',
    dealIDList: [],
    maxImpPosLength: 0,
    maxImpPosition: 0,
    weekHourList: [],
    startDate: '',
    stopDate: '',
  },
  settings: {
    campaignMargin: { presented: false, value: 0 },
  },
  tracking: {
    clickRedirect: '',
    impressionRedirect: '',
    clickS2S: '',
    impressionS2S: '',
  },
  predictorModel: {
    isAutopilotTemplate: false,
    usePredictor: false,
    predictorModelID: 0,
    targetPrice: 0,
    multiplierList: [],
  },
};

export const newKeyValue: IKeyValuePairs = {
  // @ts-ignore
  key: '',
  value: '',
};

export const newCreative: ICreativeValidation = {
  creativeID: 0,
  campaignIDList: [],
  reklID: 0,
  enabled: true,
  predictorHash: '',
  shouldStartAt: null,
  shouldStopAt: null,
  exchange: {
    statusList: [],
  },
  file: {
    duration: null,
    height: null,
    width: null,
  },
  settings: {
    labelSettings: undefined,
    viewability: EnumCreativeViewabilityProvider.ignore,
    orientation: EnumCreativeOrientation.none,
  },
  content: {
    seed: '',
    resourceList: [],
    creativeVariableList: [],
    creativeNative: {
      title: '',
      rating: '5',
      price: '0',
      description: '',
      sponsoredBy: '',
      ctaText: '',

      videoResourceID: null,
      videoRequired: false,

      iconResourceID: 0,

      mainImageResourceID: null,
      mainImageAlternativeResourceID: null,
      mainImageRequired: false,
    },
    markupVariableList: [],
    markupID: 0,
  },

  targeting: {
    languageList: {
      content: [],
      targetingContainerState: EnumTargetingContainerState.notSpecified,
    }
  }
};

export const newSharingUserToAdvertiser: ISharingUserAdvertiser = {
  advertiserEmail: '',
};

export const newElementAndListItem: IFilterAndElement = {
  not: false,
  operator: EnumFilterEntityType.Equals,
  name: '',
  value: ''
};

export const newFindResource: IFindResource = {
  search: '',
  orientation: null,
  minDuration: null,
  maxDuration: null,
  minWidth: null,
  maxWidth: null,
  minHeight: null,
  maxHeight: null,
  reklID: null,
  resourceType: EnumResourceType.Image,
};

export const newUploadResource: IUploadResource = {
  // proportion
  x: 0,
  y: 0,

  // default info
  leftTopY: 0,
  leftTopX: 0,
  width: 0,
  height: 0,
  targetWidth: 0,
  targetHeight: 0,
  description: '',

  useJpeg: true,
  skipOptimization: false,
  videoStartTime: 0,
  videoEndTime: 0,
  videoQuality: 28,

  //
  ui_type: EnumResourceType.Image,
};
