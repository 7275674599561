import { BiBrokenImage, useToggle } from '@bidease/ui-kit';

export default function Image(props: React.InputHTMLAttributes<HTMLImageElement>) {
  const [hasError, toggle] = useToggle(false);

  const onError = () => toggle(true);

  if (hasError) {
    return (
      <span className='subzoned flex h-full w-full flex-wrap items-center justify-center break-all'>
        <span className='flex flex-col items-center gap-2'><BiBrokenImage size={36} /> {props.alt}</span>
      </span>
    );
  }

  return (
    <img {...props} onError={onError} loading='lazy' />
  );
}

export function ImageNoImage() {
  return <div className='subzoned flex items-center justify-center'><BiBrokenImage size={24} /></div>;
}
