import { BideaseMail } from 'constants/enum';

import FallbackImage from './media/fallback.gif';
import Cat_1 from './media/c1.webp';
import Cat_2 from './media/c2.webp';
import Cat_3 from './media/c3.webp';
import Image from 'components/Image';

export const SendTechSupport: React.FC<{ errors: string }> = ({ errors }) => (
  <h3 className='text-center text'>
    <span className='text-silverCloud'>If issue still exists please send this string to tech support</span>
    &nbsp;
    (<a href={`mailto:${BideaseMail}`} className='text-primary'>{BideaseMail}</a>):
    <br />
    <span className='text text-red'>{errors}</span>
  </h3>
);

export const VideoView = () => {
  const randomNumber = Math.floor(Math.random() * 2 + 1);
  return (
    <p className='mb-6 text-center'>
      <picture>
        {randomNumber === 1 && <source srcSet={Cat_1} type='image/webp' />}
        {randomNumber === 2 && <source srcSet={Cat_2} type='image/webp' />}
        {randomNumber === 3 && <source srcSet={Cat_3} type='image/webp' />}
        <Image src={FallbackImage} alt='error view' className='m-auto' />
      </picture>
    </p>
  );
};
