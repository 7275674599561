import Cookies from 'js-cookie';
import { isDarkTheme } from '@bidease/ui-kit';
import { minBy, get, head, isEqual, isObject, transform, values } from 'lodash-es';

import { EnumOrderingDirection, EnumUserRole, SessionDto } from 'models/objects';

import { Regexp } from 'constants/service';
import { EnumDebounce, EnumRawResponseListName } from 'constants/enum';

export const getFirstElement = <T>(list: IInObjectDto<T> | T[]): T => (head(values(list)) as unknown as T);

export const toggleValueInArray = (collection: (string | number)[], value: string | number) => {
  if (collection.includes(value)) {
    return collection.filter(q => q !== value);
  }
  return [...collection, value];
};

export const changeOrderingDirection = (orderingElement: any) => (
  orderingElement.orderingDirection === EnumOrderingDirection.desc
    ? EnumOrderingDirection.asc : EnumOrderingDirection.desc
);

export const timeoutToReset = (fn: (args?: unknown) => void, time?: EnumDebounce, args?: unknown) => setTimeout(fn, time || EnumDebounce.resetForm, args);

export const listNumAverage = (numberArray: number[]) => numberArray.reduce((a, b) => a + b, 0) / numberArray.length;

export const hasValuesInObject = (object: object, path: (string | number)[]): boolean => {
  const val = Object.values(get(object, path, {}));
  if (val.length) {
    return !!val.filter(Boolean).find(q => q !== undefined || q !== '');
  }
  return false;
};

export const difference = (next: object, base: object) => (
  transform(next, (result: any, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  })
);

export const replaceSpace = (st: string) => st.replace(/\s/g, '');

// ts name of
export const tsNameOf = <T>(name: keyof T) => name;
export type IInObjectDto<T> = Record<string | number, T>;

export type OmitDefaultRequestValue<T> = Omit<T, 'requestTzUtc' | 'sessionToken' | 'adminSessionToken' | 'requestID' | 'clientVersion' | '__state' | 'responseResultList'> & { responseResultList?: EnumRawResponseListName[] };

export const helperFindingValueToLoadMoreEvent = <T>(obj: IInObjectDto<T>, requireKey: keyof T): any => {
  const values = Object.values(obj);
  const foundValue = minBy(values, (q: T) => q[requireKey]);
  return foundValue![requireKey];
};

export const getUrlParam = (entity: string, value?: string): string | undefined => {
  if (!import.meta.env.SSR) {
    const element = value || window.location.search;
    const arr = element.split(Regexp.locationSearch).filter(e => /=/.test(e));
    const params: Record<string, string> = {};

    for (const key of (Object as any).values(arr)) {
      const val = key.split('=');
      params[val[0]] = val[1];
    }

    if (entity in params) {
      return params[entity];
    }
    return undefined;
  }
  return undefined;
};

export const getSessionToken = () => Cookies.get('sessionToken');
export const getAdminSessionToken = () => Cookies.get('adminSessionToken');

export const getApiVersion = () => Cookies.get('apiVersion') || '';
export const setApiVersion = (apiVersion: string) => Cookies.set('apiVersion', apiVersion);

export function getAdminOrUserToken() {
  return getAdminSessionToken() || getSessionToken();
}

export const getActiveUISession = (sessionList: IInObjectDto<SessionDto> | SessionDto[]): SessionDto => {
  const values = Object.values(sessionList || {});
  if (values.length === 2) {
    return values.find(q => q.profile.userRole !== EnumUserRole.administrator)!;
  }
  return values[0];
};

export async function resetWindowCache(isReload: boolean) {
  if ('caches' in window) {
    let shouldReload = isReload;
    window.caches.keys()
      .then(async cacheNames => {
        if (cacheNames.length) {
          shouldReload = true;
        }

        for (const cacheName of cacheNames) {
          console.log('cacheNames has deleted', cacheNames);
          await window.caches.delete(cacheName);
        }
      })
      .then(() => shouldReload && window.location.reload());
  }
}

export function cartesianGridColor() {
  return isDarkTheme() ? '#777E90' : '#B1B5C3';
}

export function generateHash() {
  return Math.random().toString(36).substring(7);
}
