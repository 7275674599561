import { Button } from '@bidease/ui-kit';

import { VideoView, SendTechSupport } from 'components/BaseLayout/components';
import ServiceLayout from 'components/ServiceLayout';

import localize from 'constants/localize';

import { resetWindowCache } from 'helpers/utils';

export default function ClientError({ uid, text = 'There are some view errors' }: { uid: string, text?: string }) {
  return (
    <ServiceLayout>
      <VideoView />
      <h2 className='text-center text-large'>{text}</h2>
      <h3 className='text-center'>Try to reload the page using ctrl/cmd + shift + r</h3>
      <br />
      <SendTechSupport errors={uid} />
      <br />
      <div className='text-center'>
        <Button theme='primary' className='m-auto' type='button' onClick={() => resetWindowCache(true)}>{localize.ReloadPage}</Button>
      </div>
    </ServiceLayout>
  );
}

