import { EnumChangeSetAction } from 'models/objects';

export enum EnumMenuCampaignState {
  basicInfo = 'basic-info',
  budgetAndBidSettings = 'budget-and-bidcpm',
  settings = 'settings',
  targeting = 'targeting',
  trackingLinkGenerator = 'tracking-links',
  wbList = 'wb-list',
  segments = 'segments',
  models = 'models',
  optimization = 'optimization',
  reengagement = 'reengagement',
}

export enum EnumSelectionType {
  all = 'all',
  reset = 'reset',
}

export interface IChangeSetRequest {
  campaignID: number;
  property: string;
  action: EnumChangeSetAction;
  id?: string | number | boolean;
}

