import merge from 'lodash-es/merge';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { omit, mergeDeepRight } from 'ramda';
import { cdeebeeMergeStrategy } from '@recats/cdeebee';
import { type IDefaultNormalize } from '@recats/cdeebee/dist/definition';

import { EnumListName } from 'constants/enum';
import { difference } from 'helpers/utils';
import { CampaignDto, CreativeDto } from 'models/objects';

export const defaultNormalizeOmitChecker = (response: any) => (
  response === null || response === undefined || typeof response === 'string'
);

export const deepFullMerge = (ListName: EnumListName[]) => (
  {
    response: { responseStatus, ...response },
    cdeebee, mergeListStrategy, primaryKey,
  }: any
) => {
  const keys = Object.keys(response);
  for (const key of keys) {
    const newStorageData: any = {};
    if (response[key] instanceof Object && Object.prototype.hasOwnProperty.call(response[key], primaryKey)) {
      for (const element of response[key].data) {
        newStorageData[element[response[key][primaryKey]]] = element;
      }
      if (ListName.includes((key as EnumListName))) {
        response[key] = merge(cdeebee[key], newStorageData);
      } else {
        if (mergeListStrategy[key] === cdeebeeMergeStrategy.replace) {
          response[key] = newStorageData;
        } else {
          response[key] = mergeDeepRight(cdeebee[key], newStorageData);
        }
      }
    } else if (defaultNormalizeOmitChecker(response[key])) {
      response = omit([key], response);
    }
  }

  return response;
};

export const deepDifferenceMerge: (d: IDefaultNormalize) => object = (
  {
    response: { responseStatus, ...response },
    cdeebee, mergeListStrategy, primaryKey,
  }
) => {
  const keys = Object.keys(response);
  for (const key of keys) {
    const newStorageData: any = {};
    if (response[key] instanceof Object && Object.prototype.hasOwnProperty.call(response[key], primaryKey)) {
      for (const element of response[key].data) {
        const cdbValue = get(cdeebee, [key, element[response[key][primaryKey]]]);
        if (cdbValue && Object.prototype.hasOwnProperty.call(cdbValue, '__entity')) {
          newStorageData[element[response[key][primaryKey]]] = {
            ...element,
            __entity: merge(cdbValue.__entity, difference(element, cdbValue)),
          };
        } else {
          newStorageData[element[response[key][primaryKey]]] = element;
        }
      }
      if (mergeListStrategy[key] === cdeebeeMergeStrategy.replace) {
        response[key] = newStorageData;
      } else {
        response[key] = mergeDeepRight(cdeebee[key as keyof typeof cdeebee], newStorageData);
      }
    } else if (defaultNormalizeOmitChecker(response[key])) {
      response = omit([key], response);
    }
  }

  return response;
};

export const normalizeAndGetExtension: (d: IDefaultNormalize) => object = (
  {
    response: { responseStatus, ...response },
    cdeebee, mergeListStrategy, primaryKey,
  }
) => {
  const keys = Object.keys(response);
  for (const key of keys) {
    const newStorageData: any = {};
    if (response[key] instanceof Object && Object.prototype.hasOwnProperty.call(response[key], primaryKey)) {
      if (Object.prototype.hasOwnProperty.call(response[key], 'extension')) {
        const startKey = /^[a-z]+List$/g.test(key) ? key.split('List')[0] : key;
        response[startKey + 'Extension'] = response[key].extension;
      }

      if (key === EnumListName.reklList) {
        let index = 0;
        for (const element of response[key].data) {
          index += 1;
          newStorageData[element[response[key][primaryKey]]] = {
            ...element,
            clientPosition: index,
          };
        }
      } else {
        for (const element of response[key].data) {
          newStorageData[element[response[key][primaryKey]]] = element;
        }
      }
      if (mergeListStrategy[key] === cdeebeeMergeStrategy.replace) {
        response[key] = newStorageData;
      } else {
        // @ts-ignore
        response[key] = mergeDeepRight(cdeebee[key], newStorageData);
      }
    } else if (defaultNormalizeOmitChecker(response[key])) {
      response = omit([key], response);
    }
  }
  return response;
};

export const normalizeAndReplaceRawResponse: (d: IDefaultNormalize) => object = (
  {
    response: { responseStatus, ...response },
    cdeebee, mergeListStrategy, primaryKey,
  }
) => {
  const keys = Object.keys(response);
  for (const key of keys) {
    const newStorageData: any = {};
    if (response[key] instanceof Object && Object.prototype.hasOwnProperty.call(response[key], primaryKey)) {
      for (const element of response[key].data) {
        newStorageData[element[response[key][primaryKey]]] = element;
      }
      if (mergeListStrategy[key] === cdeebeeMergeStrategy.replace) {
        response[key] = newStorageData;
      } else {
        // @ts-ignore
        response[key] = mergeDeepRight(cdeebee[key], newStorageData);
      }
    } else if (defaultNormalizeOmitChecker(response[key])) {
      response = omit([key], response);
    }

    if (key === 'rawResponse') {
      if (Object.prototype.hasOwnProperty.call(response[key], 'groupedStats')) {
        response = { RAW_GroupedStats: response[key] };
      }
      delete response[key];
    }
  }

  return response;
};

// NOTE: delete this insane shit
// we use this piece of ****
// because we need to fallback stupid empty key behaviour
export const normalizeWithShitCases: (d: IDefaultNormalize) => object = (
  {
    response: { responseStatus, ...response },
    cdeebee, primaryKey,
  }
) => {
  const keys = Object.keys(response);
  for (const key of keys) {
    const newStorageData: any = {};
    if (response[key] instanceof Object && Object.prototype.hasOwnProperty.call(response[key], primaryKey)) {
      for (const element of response[key].data) {
        newStorageData[element[response[key][primaryKey]]] = element;
      }

      if (key === EnumListName.campaignList) {
        const campaignList: CampaignDto[] = Object.values(newStorageData);
        for (let index = 0; index < campaignList.length; index++) {
          const newCampaign = campaignList[index];
          if (newCampaign) {
            if (newCampaign?.targeting) {
              if (!Object.prototype.hasOwnProperty.call(newCampaign?.targeting, 'startDate')) {
                set(newCampaign, ['targeting', 'startDate'], null);
              }
              if (!Object.prototype.hasOwnProperty.call(newCampaign?.targeting, 'stopDate')) {
                set(newCampaign, ['targeting', 'stopDate'], null);
              }
            }
            if (newCampaign.optimization) {
              if (!Object.prototype.hasOwnProperty.call(newCampaign?.optimization, 'labelSettings')) {
                set(newCampaign, ['optimization', 'labelSettings'], null);
              }
            }
          }
        }
      }

      if (key === EnumListName.creativeList) {
        const creativeList: CreativeDto[] = Object.values(newStorageData);
        for (let index = 0; index < creativeList.length; index++) {
          const newCreative = creativeList[index];
          if (newCreative) {
            if (newCreative?.file) {
              if (!Object.prototype.hasOwnProperty.call(newCreative.file, 'duration')) {
                set(newCreative, ['file', 'duration'], null);
              }
              if (!Object.prototype.hasOwnProperty.call(newCreative.file, 'height')) {
                set(newCreative, ['file', 'height'], null);
              }
              if (!Object.prototype.hasOwnProperty.call(newCreative.file, 'width')) {
                set(newCreative, ['file', 'width'], null);
              }
            }

            if (newCreative.settings) {
              if (!Object.prototype.hasOwnProperty.call(newCreative.settings, 'labelSettings')) {
                set(newCreative, ['settings', 'labelSettings'], null);
              }
            }
          }
        }
      }
      // @ts-ignore
      response[key] = mergeDeepRight(cdeebee[key], newStorageData);
    } else if (response[key] === null || response[key] === undefined || typeof response[key] === 'string') {
      response = omit([key], response);
    }
  }

  return response;
};
